import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareReviews, {
  EasySoftwareReviewsType,
} from '../EasySoftwareReviews/EasySoftwareReviews'

export interface WidgetEasySoftwareReviewsType
  extends WidgetBase,
  EasySoftwareReviewsType {
  contentType: 'EasySoftwareReviews'
}

const WidgetEasySoftwareReviews: FunctionComponent<WidgetEasySoftwareReviewsType> = (
  props
) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
    <EasySoftwareReviews
      displayText={props.displayText}
      reviewsList={props.reviewsList}
      reviewsText={props.reviewsText}
      reviewsImage={props.reviewsImage}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareReviews
